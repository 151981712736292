import { Box, Button, FormControlLabel, Grid, MenuItem, Radio, SelectChangeEvent, useMediaQuery } from '@mui/material';
import { toggleHydraulicCalculator } from 'shared/slices';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { HEADLOSS_CALCULATION } from 'shared/constants';
import { useAppDispatch, useAppSelector, useMainlineFilters, useToast } from 'shared/hooks';
import { Input, Modal, ProductSelect, Select } from 'shared/ui';
import { CalculateHydraulic } from './lib';
import { CatalogItem } from 'shared/models';

export const HydraulicCalculator = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { showError } = useToast();
  const { openHydraulicCalc } = useAppSelector((st) => st.modals);
  const { units } = useAppSelector((st) => st.units);

  const toggle = () => dispatch(toggleHydraulicCalculator());

  const {
    masterGroups,
    masterGroupId,
    onMasterGroupChange,
    mainlineSubtype,
    onGroupTypeChange,
    classType,
    diameterValue,
    diameters,
    onClassTypeChange,
    classTypes,
    mainlineProduct,
    mainlinesFiltered,
    mainlineGroups,
    mainlineGroupsLoading,
    onMainlineChange,
    onDiameterChange,
  } = useMainlineFilters();

  const [calculationType, setCalculationType] = useState('headloss');
  const [headlossCalculation, setHeadlossCalculation] = useState('H.W');
  const [values, setValues] = useState({
    headloss: 0,
    numberOfOutlets: 0,
    additionalHeadloss: 0,
    KD: 0,
    distanceBetweenOutlets: 0,
    outletFlow: 0,
    internalDiameter: 0,
    totalFlow: 20,
    velocity: 0,
    length: 100,
    hwConstant: 140,
  });

  const handleMainlineChange = (e: any, item: CatalogItem) => {
    onMainlineChange(null, item);
    setValues((prev) => ({ ...prev, internalDiameter: item?.INTRNL }));
  };

  const handleHeadlossCalculationChange = (e: SelectChangeEvent<unknown>) => {
    setHeadlossCalculation(e.target.value as string);
  };

  const handleCalculationTypeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setCalculationType(e.target.value);
  };

  const handleValuesChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setValues((prev) => ({ ...prev, [e.target.name]: +e.target.value }));
  };

  const handleCalculateClick = () => {
    const result = CalculateHydraulic(
      {
        NumberOfOutlets: values.numberOfOutlets,
        OutletDistance: values.distanceBetweenOutlets,
        OutletFlow: values.outletFlow,
        Q: values.totalFlow,
        Dia: values.internalDiameter,
        HW: values.hwConstant,
        Len: values.length,
        HL: values.headloss,
        kd: values.KD,
        velocity: values.velocity,
        addlHeadloss: values.additionalHeadloss,
        headlossCalc: headlossCalculation,
        calcType: calculationType,
      },
      units,
    );

    if (result.error) {
      showError(result.error);
    }

    if (result.AHL) {
      const formattedValue = isFinite(result.AHL) ? result.AHL : 0;
      setValues((prev) => ({ ...prev, additionalHeadloss: formattedValue }));
    }
    if (result.HL) {
      const formattedValue = isFinite(result.HL) ? result.HL : 0;
      setValues((prev) => ({ ...prev, headloss: formattedValue }));
    }
    if (result.Q) {
      const formattedValue = isFinite(result.Q) ? result.Q : 0;
      setValues((prev) => ({ ...prev, totalFlow: formattedValue }));
    }
    if (result.kd) {
      const formattedValue = isFinite(result.kd) ? result.kd : 0;
      setValues((prev) => ({ ...prev, KD: formattedValue }));
    }
    if (result.velocity) {
      const formattedValue = isFinite(result.velocity) ? result.velocity : 0;
      setValues((prev) => ({ ...prev, velocity: formattedValue }));
    }
    if (result.Len) {
      const formattedValue = isFinite(result.Len) ? result.Len : 0;
      setValues((prev) => ({ ...prev, length: formattedValue }));
    }
    if (result.Dia) {
      const formattedValue = isFinite(result.Dia) ? result.Dia : 0;
      setValues((prev) => ({ ...prev, internalDiameter: formattedValue }));
    }
  };

  useEffect(() => {
    if (mainlineProduct) {
      setValues((prev) => ({ ...prev, internalDiameter: mainlineProduct.INTRNL }));
    }
  }, [mainlineProduct]);

  const isMobile = useMediaQuery('(max-width:550px)');

  return (
    <Modal maxWidth='sm' title={t('hydraulicCalculator')} open={openHydraulicCalc} onClose={toggle}>
      <Box>
        <Grid container columnSpacing={1} alignItems='center'>
          <Grid item xs={9}>
            <FormControlLabel
              sx={{ fontSize: '12px', textTransform: 'uppercase', color: 'secondary.main' }}
              control={
                <Radio
                  name='calculation-type'
                  onChange={handleCalculationTypeChange}
                  value='headloss'
                  checked={calculationType === 'headloss'}
                />
              }
              label={`${t('headloss')} (${units.length})`}
            />
          </Grid>
          <Grid item xs={3}>
            <Input
              value={values.headloss}
              onChange={handleValuesChange}
              name='headloss'
              isResultBox={calculationType === 'headloss'}
              type='number'
            />
          </Grid>
        </Grid>

        <Grid container columnSpacing={1}>
          <Grid item xs={6}>
            <Input
              value={values.numberOfOutlets}
              onChange={handleValuesChange}
              name='numberOfOutlets'
              label={`${t('numOutlets')}`}
              type='number'
            />
          </Grid>
          <Grid item xs={6}>
            {/*<Input*/}
            {/*  value={values.additionalHeadloss}*/}
            {/*  onChange={handleValuesChange}*/}
            {/*  name='additionalHeadloss'*/}
            {/*  label={`${t('addlHeadloss')} (${units.pressure})`}*/}
            {/*  type='number'*/}
            {/*/>*/}
          </Grid>
          {/*<Grid item xs={4}>*/}
          {/*  <Input value={values.KD} onChange={handleValuesChange} name='KD' label={`${t('KD')}`} type='number' />*/}
          {/*</Grid>*/}
          <Grid item xs={6}>
            <Input
              value={values.distanceBetweenOutlets}
              onChange={handleValuesChange}
              name='distanceBetweenOutlets'
              label={`${t('outletDistance')} (${units.length})`}
              type='number'
            />
          </Grid>
          <Grid item xs={6}>
            <Input
              value={values.outletFlow}
              onChange={handleValuesChange}
              name='outletFlow'
              label={`${t('outletFlow')} (${units.totalFlow})`}
              type='number'
            />
          </Grid>
          <Grid item xs={6}>
            <Select label={`${t('type')}`} value={masterGroupId} onChange={onMasterGroupChange}>
              {masterGroups.map((item) => (
                <MenuItem key={item.ID} value={item.ID}>
                  {item.MASTERGROUPNAME}
                </MenuItem>
              ))}
            </Select>
          </Grid>
          <Grid item xs={6}>
            <Select
              label={`${t('subtype')}`}
              value={mainlineSubtype}
              onChange={onGroupTypeChange}
              loading={mainlineGroupsLoading}
              disabled={mainlineGroupsLoading}
            >
              {mainlineGroups.map((item) => (
                <MenuItem key={item.GROUPS} value={item.GROUPS}>
                  {item.GROUPTYPENAME}
                </MenuItem>
              ))}
            </Select>
          </Grid>
          <Grid item xs={6}>
            <Select
              label={`${t('class')}`}
              value={classType}
              onChange={onClassTypeChange}
              disabled={mainlineGroupsLoading}
            >
              {classTypes
                .sort((a, b) => Number(a) - Number(b))
                .map((item) => (
                  <MenuItem key={item} value={item}>
                    {item === '' ? 'All' : item}
                  </MenuItem>
                ))}
            </Select>
          </Grid>
          <Grid item xs={6}>
            <Select
              label={`${t('dia')}`}
              value={diameterValue}
              onChange={onDiameterChange}
              disabled={mainlineGroupsLoading}
            >
              {diameters
                .sort((a, b) => Number(a) - Number(b))
                .map((item) => (
                  <MenuItem key={item} value={item}>
                    {item === '' ? 'All' : item}
                  </MenuItem>
                ))}
            </Select>
          </Grid>
        </Grid>

        <Grid container>
          <ProductSelect
            label={`${t('product')}`}
            value={mainlineProduct ?? null}
            onChange={handleMainlineChange}
            options={mainlinesFiltered}
          />
        </Grid>
        <Grid container columnSpacing={1} alignItems='center'>
          <Grid item xs={9}>
            <FormControlLabel
              sx={{ fontSize: '12px', textTransform: 'uppercase', color: 'secondary.main' }}
              control={
                <Radio
                  value='internalDia'
                  name='calculation-type'
                  onChange={handleCalculationTypeChange}
                  checked={calculationType === 'internalDia'}
                />
              }
              label={`${t('internalDia')} (${units.pipeDiameter})`}
            />
          </Grid>
          <Grid item xs={isMobile ? 12 : 3}>
            <Input
              value={values.internalDiameter}
              onChange={handleValuesChange}
              name='internalDiameter'
              isResultBox={calculationType === 'internalDia'}
              type='number'
            />
          </Grid>
        </Grid>
        <Grid container columnSpacing={1} alignItems='center'>
          <Grid item xs={9}>
            <FormControlLabel
              sx={{ fontSize: '12px', textTransform: 'uppercase', color: 'secondary.main' }}
              control={
                <Radio
                  value='totalFlow'
                  name='calculation-type'
                  onChange={handleCalculationTypeChange}
                  checked={calculationType === 'totalFlow'}
                />
              }
              label={`${t('totalFlow')} (${units.totalFlow})`}
            />
          </Grid>
          <Grid item xs={isMobile ? 12 : 3}>
            <Input
              value={values.totalFlow}
              onChange={handleValuesChange}
              name='totalFlow'
              isResultBox={calculationType === 'totalFlow'}
              type='number'
            />
          </Grid>
        </Grid>
        <Grid container columnSpacing={1}>
          <Grid item xs={6}>
            <Input
              value={values.velocity}
              onChange={handleValuesChange}
              name='velocity'
              label={`${t('velocity')} (${units.velocity})`}
              type='number'
            />
          </Grid>
        </Grid>
        <Grid container columnSpacing={1} alignItems='center'>
          <Grid item xs={9}>
            <FormControlLabel
              sx={{ fontSize: '12px', textTransform: 'uppercase', color: 'secondary.main' }}
              control={
                <Radio
                  value='length'
                  name='calculation-type'
                  onChange={handleCalculationTypeChange}
                  checked={calculationType === 'length'}
                />
              }
              label={`${t('length')} (${units.length})`}
            />
          </Grid>
          <Grid item xs={isMobile ? 12 : 3}>
            <Input
              value={values.length}
              onChange={handleValuesChange}
              name='length'
              isResultBox={calculationType === 'length'}
              type='number'
            />
          </Grid>
        </Grid>
        <Grid container columnSpacing={1}>
          <Grid item xs={6}>
            <Select label={`${t('hlCalc')}`} value={headlossCalculation} onChange={handleHeadlossCalculationChange}>
              {HEADLOSS_CALCULATION.map((item) => (
                <MenuItem key={item.value} value={item.value}>
                  {item.label}
                </MenuItem>
              ))}
            </Select>
          </Grid>
        </Grid>
        <Grid container columnSpacing={1} mb={2}>
          {headlossCalculation !== 'D.W' && (
            <Grid item xs={6}>
              <Input
                value={values.hwConstant}
                onChange={handleValuesChange}
                name='hwConstant'
                label={`${t('hwConst')}`}
                type='number'
              />
            </Grid>
          )}
        </Grid>
        <Grid container columnSpacing={1}>
          <Grid item xs={6}>
            <Button onClick={handleCalculateClick} variant='contained' fullWidth>
              {t('calculate')}
            </Button>
          </Grid>
          <Grid item xs={6}>
            <Button onClick={toggle} variant='outlined' fullWidth>
              {t('cancel')}
            </Button>
          </Grid>
        </Grid>
      </Box>
    </Modal>
  );
};
