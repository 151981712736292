import { Box, Grid, useMediaQuery } from '@mui/material';
import { ChangeEvent, FC } from 'react';
import { useTranslation } from 'react-i18next';
import { spacing_ico } from 'shared/assets';
import { useAppDispatch, useAppSelector } from 'shared/hooks';
import { setEDCStateValue } from 'shared/slices';
import { Accordion, IconLabel, Input, PaperBox, ShepherdBlock } from 'shared/ui';

const SpacingContent: FC = () => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const isTablet = useMediaQuery('(max-width:850px)');
  const { edcValues } = useAppSelector((state) => state.edcState);
  const { units } = useAppSelector((state) => state.units);

  const onEmitterSpacingChange = (e: ChangeEvent<HTMLInputElement>) => {
    dispatch(setEDCStateValue({ emitterSpacing: +e.target.value }));
  };
  const onLateralSpacingChange = (e: ChangeEvent<HTMLInputElement>) => {
    dispatch(setEDCStateValue({ lateralSpacing: +e.target.value }));
  };
  const onEdgeInRowChange = (e: ChangeEvent<HTMLInputElement>) => {
    dispatch(setEDCStateValue({ edgeInRow: +e.target.value }));
  };
  const onEdgeOffRowChange = (e: ChangeEvent<HTMLInputElement>) => {
    dispatch(setEDCStateValue({ edgeOffRow: +e.target.value }));
  };
  const onBedWidthChange = (e: ChangeEvent<HTMLInputElement>) => {
    dispatch(setEDCStateValue({ bedWidth: +e.target.value }));
  };

  return (
    <Grid item container xs={12} columnSpacing={isTablet ? 1 : 3} rowSpacing={1}>
      <Grid item container xs={6}>
        <ShepherdBlock id='edc-16'>
          <Grid item container rowSpacing={1}>
            <Grid item xs={12}>
              <Input
                label={`${t('emitter')} (${units.emitterSpacing})`}
                value={edcValues.emitterSpacing}
                onChange={onEmitterSpacingChange}
              />
            </Grid>
            <Grid item xs={12}>
              <Input
                label={`${t('lateral')} (${units.lateralSpacing})`}
                value={edcValues.lateralSpacing}
                onChange={onLateralSpacingChange}
              />
            </Grid>
            <Grid item xs={12}>
              <Input
                label={`${t('bedWidth')} (${units.length})`}
                value={edcValues.bedWidth}
                onChange={onBedWidthChange}
              />
            </Grid>
          </Grid>
        </ShepherdBlock>
      </Grid>

      <Grid item container xs={6}>
        <ShepherdBlock id='edc-17'>
          <Grid item container rowSpacing={1}>
            <Grid item xs={12}>
              <Input label={`${t('edgeInRow')}`} value={edcValues.edgeInRow} onChange={onEdgeInRowChange} />
            </Grid>

            <Grid item xs={12}>
              <Input label={`${t('edgeOffRow')}`} value={edcValues.edgeOffRow} onChange={onEdgeOffRowChange} />
            </Grid>

            <Grid item xs={12}>
              <Input label={`${t('sprinklersPerHa')}`} value={edcValues.sprinklersPerHa} isResultBox />
            </Grid>
          </Grid>
        </ShepherdBlock>
      </Grid>
    </Grid>
  );
};

export const SpacingBlock: FC = () => {
  const isTablet = useMediaQuery('(max-width:850px)');
  const { t } = useTranslation();

  return (
    <Box width='100%' mt={1}>
      {isTablet ? (
        <Accordion header={<IconLabel iconSrc={spacing_ico}>{t('spacing')}</IconLabel>}>
          <SpacingContent />
        </Accordion>
      ) : (
        <Box>
          <IconLabel iconSrc={spacing_ico}>{t('spacing')}</IconLabel>
          <PaperBox height='200px'>
            <SpacingContent />
          </PaperBox>
        </Box>
      )}
    </Box>
  );
};
