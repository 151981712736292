import { createSlice } from '@reduxjs/toolkit';

interface ModalState {
  openHydraulicCalc: boolean;
  openPumpCalc: boolean;
  openElectricCalc: boolean;
  openInjectionRateCalc: boolean;
  openPressureFlowCalc: boolean;
  openTemplate: boolean;
  openNewProject: boolean;
  openProject: boolean;
  openSaveAsProject: boolean;
  openRegions: boolean;
  openUnits: boolean;
  openLanguage: boolean;
  openExit: boolean;
  openLateralDefineLengthReport: boolean;
  openLateralMaxLengthReport: boolean;
  openEnterLengthValues: boolean;
  openSubmainReport: boolean;
  openMainlineReport: boolean;
  openSystemSummaryReport: boolean;
  openEDCGraph: boolean;
  openEDCTables: boolean;
  openAddToHome: boolean;
}

const initialState: ModalState = {
  openHydraulicCalc: false,
  openPumpCalc: false,
  openElectricCalc: false,
  openInjectionRateCalc: false,
  openPressureFlowCalc: false,
  openTemplate: false,
  openNewProject: false,
  openProject: false,
  openSaveAsProject: false,
  openRegions: false,
  openUnits: false,
  openLanguage: false,
  openExit: false,
  openLateralDefineLengthReport: false,
  openLateralMaxLengthReport: false,
  openEnterLengthValues: false,
  openSubmainReport: false,
  openMainlineReport: false,
  openSystemSummaryReport: false,
  openEDCGraph: false,
  openEDCTables: false,
  openAddToHome: false,
};

const modalSlice = createSlice({
  name: 'modals',
  initialState,
  reducers: {
    toggleHydraulicCalculator: (state) => {
      state.openHydraulicCalc = !state.openHydraulicCalc;
    },
    togglePumpCalculator: (state) => {
      state.openPumpCalc = !state.openPumpCalc;
    },
    toggleElectricCalculator: (state) => {
      state.openElectricCalc = !state.openElectricCalc;
    },
    toggleInjectionRateCalculator: (state) => {
      state.openInjectionRateCalc = !state.openInjectionRateCalc;
    },
    togglePressureFlowCalculator: (state) => {
      state.openPressureFlowCalc = !state.openPressureFlowCalc;
    },
    toggleTemplate: (state) => {
      state.openTemplate = !state.openTemplate;
    },
    toggleNewProject: (state) => {
      state.openNewProject = !state.openNewProject;
    },
    toggleOpenProject: (state) => {
      state.openProject = !state.openProject;
    },
    toggleSaveAsProject: (state) => {
      state.openSaveAsProject = !state.openSaveAsProject;
    },
    toggleRegions: (state) => {
      state.openRegions = !state.openRegions;
    },
    toggleUnits: (state) => {
      state.openUnits = !state.openUnits;
    },
    toggleLanguage: (state) => {
      state.openLanguage = !state.openLanguage;
    },
    toggleExit: (state) => {
      state.openExit = !state.openExit;
    },
    toggleLateralDefineLengthReport: (state) => {
      state.openLateralDefineLengthReport = !state.openLateralDefineLengthReport;
    },
    toggleLateralMaxLengthReport: (state) => {
      state.openLateralMaxLengthReport = !state.openLateralMaxLengthReport;
    },
    toggleEnterLengthValues: (state) => {
      state.openEnterLengthValues = !state.openEnterLengthValues;
    },
    toggleSubmainReport: (state) => {
      state.openSubmainReport = !state.openSubmainReport;
    },
    toggleMainlineReport: (state) => {
      state.openMainlineReport = !state.openMainlineReport;
    },
    toggleSystemSummaryReport: (state) => {
      state.openSystemSummaryReport = !state.openSystemSummaryReport;
    },
    toggleEDCGraph: (state) => {
      state.openEDCGraph = !state.openEDCGraph;
    },
    toggleEDCTables: (state) => {
      state.openEDCTables = !state.openEDCTables;
    },
    toggleAddToHome: (state) => {
      state.openAddToHome = !state.openAddToHome;
    },
    showAddToHome: (state) => {
      state.openAddToHome = true;
    },
  },
});

export const {
  toggleHydraulicCalculator,
  togglePumpCalculator,
  toggleElectricCalculator,
  toggleInjectionRateCalculator,
  togglePressureFlowCalculator,
  toggleTemplate,
  toggleNewProject,
  toggleOpenProject,
  toggleSaveAsProject,
  toggleRegions,
  toggleUnits,
  toggleLanguage,
  toggleExit,
  toggleLateralDefineLengthReport,
  toggleLateralMaxLengthReport,
  toggleEnterLengthValues,
  toggleSubmainReport,
  toggleMainlineReport,
  toggleSystemSummaryReport,
  toggleEDCGraph,
  toggleEDCTables,
  toggleAddToHome,
  showAddToHome,
} = modalSlice.actions;

export const modalActions = modalSlice.actions;

export const modalReducer = modalSlice.reducer;
