import { Box, Button, Checkbox, FormControlLabel, Link, Stack, Typography, styled, useMediaQuery } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { useEffect, useMemo, useRef, useState } from 'react';
import { useNavigate } from 'react-router';
import { AuthInput } from 'shared/ui';
import { LoginBody } from 'shared/models';
import { useAppDispatch, useAppSelector, useDialog, useToast } from 'shared/hooks';
import { useTranslation } from 'react-i18next';
import { ImportantUpdateModal, LoginHeader, EndUserLicenseAcceptance } from 'features';
import { loginThunk } from 'shared/slices';

const Text = styled(Typography)(() => ({
  fontSize: '11px',
  fontWeight: 400,
  margin: '20px 0 8px 20px',
  textDecoration: 'none',
}));

const btnStyles = {
  textTransform: 'capitalize',
  borderRadius: '8px',
  minWidth: '120px',
  height: '48px',
  fontSize: 18,
};

export const LoginForm = () => {
  // const { open: openAdditionalTerms, toggle: toggleAdditionalTerms } = useDialog();
  const { open: openAcceptance, toggle: toggleAcceptance } = useDialog();

  const { showError } = useToast();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const isMobile = useMediaQuery('(max-width:510px)');
  const dispatch = useAppDispatch();
  const loading = useAppSelector((state) => state.auth.loading);

  const nameInputRef = useRef<HTMLInputElement>(null);

  const [loginData, setLoginData] = useState({ username: '', password: '' });
  const [rememberMe, setRememberMe] = useState(false);
  const [isDisabledEmailInput, setIsDisabledEmailInput] = useState(false);

  const [isForOldHtUserPopup, setIsForOldHtUserPopup] = useState(false);
  const [isHtAccountWasUpgraded, setIsHtAccountWasUpgraded] = useState(false);

  const [navigateRegisterLink, setNavigateRegisterLink] = useState('');

  const handleLoginDataChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setLoginData((prev) => ({ ...prev, [e.target.name]: e.target.value }));
    setIsHtAccountWasUpgraded(false);
  };
  const handleRememberMeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setRememberMe(e.target.checked);
  };

  const statusCallbacks: { [key: number]: () => void } = useMemo(
    () => ({
      400: () => showError(t('wrong_login_credentials')),
      405: () => {
        setNavigateRegisterLink(`/register?oldUserName=${loginData.username}`);
        setIsForOldHtUserPopup(true);
      },
      411: () => {
        showError(t('the_user_need_to_agree_to_term_and_condition'));
        toggleAcceptance();
      },
      608: () => {
        setNavigateRegisterLink(`/register?recreating=1&oldUserName=${loginData.username}`);
        setIsForOldHtUserPopup(true);
      },
      607: () => setIsHtAccountWasUpgraded(true),
      609: () => setIsHtAccountWasUpgraded(true),
      610: () => setIsHtAccountWasUpgraded(true),
    }),
    [loginData.username]
  );

  const loginBody = {
    user: loginData.username,
    password: loginData.password,
    rememberMe,
    isFromOldHT: true,
  };

  const handleLoginClick = async (cachedBody?: LoginBody) => {
    const body: LoginBody = cachedBody || loginBody;

    setIsHtAccountWasUpgraded(false);
    localStorage.removeItem('ht-redirected-email');

    if (rememberMe) {
      localStorage.setItem('ht-saved-username', loginData.username);
      localStorage.setItem('ht-saved-password', loginData.password);
    } else {
      localStorage.removeItem('ht-saved-username');
      localStorage.removeItem('ht-saved-password');
    }

    try {
      await dispatch(loginThunk(body)).unwrap();

      navigate('/lateral');
    } catch (e: any) {
      console.log(e);

      const callback = statusCallbacks[e];

      if (callback) {
        callback();
      } else {
        showError(t('wrong_login_credentials'));
      }
    }
  };

  const onCloseImportantUpdateModal = () => {
    navigate(navigateRegisterLink);
    localStorage.removeItem('ht-redirected-email');
  };
  const onRegisterClick = () => {
    navigate('/register');
    localStorage.removeItem('ht-redirected-email');
  };
  const onForgotPasswordClick = () => {
    window.location.href = String(process.env.REACT_APP_FORGOT_PASSWORD_URL);
    localStorage.removeItem('ht-redirected-email');
  };

  useEffect(() => {
    const htRedirectedEmail = localStorage.getItem('ht-redirected-email');

    if (htRedirectedEmail) {
      setIsDisabledEmailInput(true);
      setLoginData({ username: htRedirectedEmail, password: '' });
      return;
    }

    const userJson = localStorage.getItem('user');

    if (userJson) {
      const userData = JSON.parse(decodeURIComponent(window.atob(userJson))) as LoginBody;

      if (userData.rememberMe) {
        handleLoginClick(userData);
        return;
      }
    }

    const username = localStorage.getItem('ht-saved-username');
    const password = localStorage.getItem('ht-saved-password');

    if (username && password) {
      setLoginData({ username, password });
      setRememberMe(true);
    }
  }, []);

  const isLoginButtonDisabled = !loginData.username.length || !loginData.password.length;

  return (
    <>
      <Stack alignItems="center" sx={{}}>
        <Box
          sx={{
            p: '40px 24px 20px 24px',
            width: isMobile ? '100%' : '480px',
            boxShadow: '0px -0.5px 4px 0px rgba(0, 0, 0, 0.25)',
            borderRadius: '16px',
          }}
        >
          <LoginHeader />

          <Stack mt={1} sx={{}}>
            <Box flexBasis="100%">
              <Text>{t('login')}</Text>
              <AuthInput
                name="username"
                value={loginData.username}
                onChange={handleLoginDataChange}
                required
                autoFocus
                placeholder={`${t('enter_email')}`}
                disabled={isDisabledEmailInput}
                inputRef={nameInputRef}
              />

              {isHtAccountWasUpgraded && (
                <Typography
                  sx={{
                    mt: '8px',
                    color: '#F00',
                    fontFamily: 'Roboto',
                    fontSize: '18px',
                    lineHeight: '130%',
                    letterSpacing: '0.18px',
                  }}
                >
                  {t('your_account_has_been_upgraded_use_your_email_and_the_new_password_to_login')}
                </Typography>
              )}
            </Box>

            <Box flexBasis="100%" mb={2}>
              <Text>{t('password')}</Text>

              <AuthInput
                name="password"
                value={loginData.password}
                onChange={handleLoginDataChange}
                required
                placeholder={`${t('enter_password')}`}
                password={true}
              />
            </Box>

            <Box mt={1}>{/* <Terms toggleAdditionalTerms={toggleAdditionalTerms} /> */}</Box>

            <Box display="flex" justifyContent="space-between" alignItems="center" mt={1} flexWrap="wrap">
              <FormControlLabel
                control={<Checkbox value={rememberMe} checked={rememberMe} onChange={handleRememberMeChange} />}
                label={`${t('rememberMe')}`}
              />
              <Typography variant="body2">
                <Link
                  color="primary"
                  onClick={onForgotPasswordClick}
                  sx={{ cursor: 'pointer', color: 'blue', textDecoration: 'none' }}
                >
                  {t('forgotPassword')}
                </Link>
              </Typography>
            </Box>

            <Stack direction="row" justifyContent="flex-end" columnGap={3} mt={1}>
              <Box>
                <LoadingButton
                  onClick={() => handleLoginClick()}
                  loading={loading}
                  disabled={isLoginButtonDisabled}
                  fullWidth
                  variant="contained"
                  color="primary"
                  sx={{ ...btnStyles }}
                >
                  {t('login')}
                </LoadingButton>
              </Box>
              <Box>
                <Button
                  onClick={onRegisterClick}
                  variant="outlined"
                  sx={{
                    ...btnStyles,
                    color: 'black',
                    borderColor: 'black',
                  }}
                >
                  {t('register')}
                </Button>
              </Box>
            </Stack>
          </Stack>
        </Box>
      </Stack>

      <EndUserLicenseAcceptance
        open={openAcceptance}
        handleClose={toggleAcceptance}
        email={loginData.username}
        loginCallback={() => handleLoginClick(loginBody)}
      />

      <ImportantUpdateModal show={isForOldHtUserPopup} onClose={onCloseImportantUpdateModal} />

      {/* <EndUserLicense
        open={openAdditionalTerms}
        onAccept={toggleAdditionalTerms}
        onDoNotAccept={toggleAdditionalTerms}
      /> */}
      {/*<AccountActivationRequiredModal show={true} onClose={toggleAdditionalTerms}*/}
      {/*                                backToLogin={() => navigate('login')} />*/}
      {/* <ForgotPassword open={openForgotPassword} handleClose={toggleForgotPassword} /> */}
    </>
  );
};
