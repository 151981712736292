import { Box, Button } from '@mui/material';
import { togglePumpCalculator } from 'shared/slices';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from 'shared/hooks';
import { Input, Modal } from 'shared/ui';
import { calculatePowerUnit, calculateWaterPower } from './lib';
import { useState } from 'react';

export const PumpCalculator = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { openPumpCalc } = useAppSelector((st) => st.modals);
  const { units } = useAppSelector((st) => st.units);

  const [values, setValues] = useState({ flow: 0, head: 0, efficiency: 70, power: 0 });

  const handleValuesChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setValues((prev) => ({ ...prev, [e.target.name]: +e.target.value }));
  };

  const toggle = () => dispatch(togglePumpCalculator());

  const handleCalculateClick = () => {
    const { flow, efficiency, head } = values;

    const { power } = calculateWaterPower({ flow, efficiency, head }, units);

    setValues((prev) => ({ ...prev, power }));
  };

  return (
    <Modal maxWidth='xs' title={t('pumpCalculator')} open={openPumpCalc} onClose={toggle}>
      <Box>
        <Input
          value={values.flow}
          onChange={handleValuesChange}
          fullWidth
          type='number'
          name='flow'
          label={`${t('flow')} (${units.totalFlow})`}
        />
        <Input
          value={values.head}
          onChange={handleValuesChange}
          fullWidth
          type='number'
          name='head'
          label={`${t('head')} (${units.pressure})`}
        />
        <Input
          value={values.efficiency}
          onChange={handleValuesChange}
          fullWidth
          type='number'
          name='efficiency'
          label={`${t('efficiency')} (%)`}
        />
        <Input
          value={values.power}
          fullWidth
          disabled
          name='power'
          label={`${t('power')} ${calculatePowerUnit(units.totalFlow)}`}
          isResultBox
        />
        <Box display='flex' columnGap={1} mt={2}>
          <Button onClick={handleCalculateClick} variant='contained' sx={{ flexBasis: '50%' }}>
            {t('calculate')}
          </Button>

          <Button onClick={toggle} variant='outlined' sx={{ flexBasis: '50%' }}>
            {t('cancel')}
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};
