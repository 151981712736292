import { Visibility, VisibilityOff } from '@mui/icons-material';
import { IconButton, InputAdornment, TextField, TextFieldProps } from '@mui/material';
import { forwardRef, useState } from 'react';

export const autoFillColor = {
  '&:-webkit-autofill': {
    WebkitBoxShadow: '0 0 0 30px #F5FBFF inset !important',
    WebkitTextFillColor: 'black !important',
  },
  '&:-webkit-autofill:hover': {
    WebkitBoxShadow: '0 0 0 30px #F5FBFF inset !important',
    WebkitTextFillColor: 'black !important',
  },
  '&:-webkit-autofill:focus': {
    WebkitBoxShadow: '0 0 0 30px #F5FBFF inset !important',
    WebkitTextFillColor: 'black !important',
  },
  '&:-webkit-autofill:active': {
    WebkitBoxShadow: '0 0 0 30px #F5FBFF inset !important',
    WebkitTextFillColor: 'black !important',
  },
};

type Props = TextFieldProps & {
  password?: boolean;
};

export const AuthInput = forwardRef<unknown, Props>((props, ref) => {
  const [isShow, setIsShow] = useState(false);
  const handleShow = () => setIsShow(!isShow);

  return (
    <TextField
      fullWidth
      InputLabelProps={{ shrink: true }}
      inputProps={{
        'data-hj-allow': '',
        sx: {
          ...autoFillColor,
          p: 0,
          px: '20px',
          height: '48px',
        },
        min: 0,
        ...props.inputProps,
      }}
      inputRef={ref}
      color="primary"
      sx={{
        bgcolor: props.disabled ? 'rgba(242, 242, 242, 1)' : 'transparent',
        fieldset: { display: props.disabled ? 'none' : 'block' },
        borderRadius: '6px',
      }}
      type={props.password ? (isShow ? 'text' : 'password') : props.type}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            {props.password && (
              <IconButton onClick={handleShow}>
                {isShow ? (
                  <Visibility sx={{ color: 'darksome.main', fontSize: '18px' }} />
                ) : (
                  <VisibilityOff sx={{ color: 'darksome.main', fontSize: '18px' }} />
                )}
              </IconButton>
            )}
          </InputAdornment>
        ),
      }}
      {...props}
    />
  );
});
