import { Box, Grid, TextField, Tooltip, Typography, useMediaQuery, useTheme } from '@mui/material';
import { ChangeEvent, FC, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { results_ico } from 'shared/assets';
import { useAppDispatch, useAppSelector } from 'shared/hooks';
import { calculateSC, calculateWettest, getTransformedArray } from 'shared/lib';
import { HandleField } from 'shared/lib/calculation/edc/helper/HandleFiled/HandleField';
import { InputEmitterCalculation } from 'shared/lib/calculation/edc/helper/InputEmitterCalculation';
import { EDCCalcResult } from 'shared/lib/calculation/models';
import { setEDCCalcResult, setEDCStateError, setEDCStateValue } from 'shared/slices';
import { Accordion, IconLabel, Input, PaperBox } from 'shared/ui';

const WettestPercentInput = () => {
  const dispatch = useAppDispatch();

  const { palette } = useTheme();
  const isTablet = useMediaQuery('(max-width:850px)');

  const { edcValues } = useAppSelector((state) => state.edcState);

  const onWettestPercentChange = (e: ChangeEvent<HTMLInputElement>) => {
    dispatch(setEDCStateValue({ wettestPercent: +e.target.value }));
  };

  return (
    <Box sx={{ position: 'absolute', top: 0, right: 0, bottom: 0, width: isTablet ? '50%' : '40%' }}>
      <Box display='flex' alignItems='flex-end'>
        <Tooltip title='%' placement='top'>
          <Box display='flex' justifyContent='center' width='100%'>
            <Typography
              noWrap
              sx={{
                fontSize: '11px',
                color: palette.secondary.main,
                marginBottom: '3px',
              }}
            >
              {'%'}
            </Typography>
          </Box>
        </Tooltip>
      </Box>

      <TextField
        fullWidth
        type='number'
        sx={{
          background: palette.info.main,
          borderRadius: '9px',
        }}
        inputProps={{
          min: 1,
          step: 1,
          max: 100,
        }}
        InputProps={{
          sx: {
            height: '34px',
            borderRadius: '9px',
            borderColor: palette.secondary.main,
            paddingLeft: 0,
          },
        }}
        color='primary'
        value={edcValues.wettestPercent}
        onChange={onWettestPercentChange}
      />
    </Box>
  );
};
const SCPercentInput = () => {
  const dispatch = useAppDispatch();

  const { palette } = useTheme();
  const isTablet = useMediaQuery('(max-width:850px)');

  const { edcValues } = useAppSelector((state) => state.edcState);

  const onScPercentChange = (e: ChangeEvent<HTMLInputElement>) => {
    dispatch(setEDCStateValue({ scPercent: +e.target.value }));
  };

  return (
    <Box sx={{ position: 'absolute', top: 0, right: 0, bottom: 0, width: isTablet ? '50%' : '40%' }}>
      <Box display='flex' alignItems='flex-end'>
        <Tooltip title='%' placement='top'>
          <Box display='flex' justifyContent='center' width='100%'>
            <Typography
              noWrap
              sx={{
                fontSize: '11px',
                color: palette.secondary.main,
                marginBottom: '3px',
              }}
            >
              {'%'}
            </Typography>
          </Box>
        </Tooltip>
      </Box>

      <TextField
        fullWidth
        type='number'
        sx={{
          background: palette.info.main,
          borderRadius: '9px',
        }}
        inputProps={{
          min: 1,
          step: 1,
          max: 100,
        }}
        InputProps={{
          sx: {
            height: '34px',
            borderRadius: '9px',
            borderColor: palette.secondary.main,
            paddingLeft: 0,
          },
        }}
        color='primary'
        value={edcValues.scPercent}
        onChange={onScPercentChange}
      />
    </Box>
  );
};

const ResultsContent: FC = () => {
  const dispatch = useAppDispatch();

  const { t } = useTranslation();
  const isTablet = useMediaQuery('(max-width:850px)');
  const isMobile = useMediaQuery('(max-width:600px)');

  const { units } = useAppSelector((state) => state.units);
  const { currentEmitter } = useAppSelector((state) => state.emittersEDC);
  const { calcResult, edcValues, edcErrors } = useAppSelector((state) => state.edcState);

  const {
    calcResult: { tempdrainat },
    edcValues: {
      emitterSpacing,
      lateralSpacing,
      edgeInRow,
      edgeOffRow,
      bedWidth,
      placement,
      edge,
      lateral,
      mouseClickPositionY1,
      mouseClickPositionY2,
      scPercent,
      wettestPercent,
    },
    edcPlantsValues: {
      betweenPlants,
      betweenRows,
      plantDiameter,
      staggeredRows,
      inRows,
      offRows,
      centerPlantsBetweenLaterals,
    },
  } = useAppSelector((state) => state.edcState);

  useEffect(() => {
    if (!Object.keys(currentEmitter)) return;

    const defaultValues = InputEmitterCalculation(currentEmitter, units);

    // const calculatedEmitterSpacing = edcValues.placement === 3 ? currentEmitter.Range * 2 : currentEmitter.Range;
    // const calculatedLateralSpacing = edcValues.placement === 3 ? currentEmitter.Range * 2 : currentEmitter.Range;

    dispatch(
      setEDCStateValue({
        emitterSpacing: defaultValues.emitterSpacing,
        lateralSpacing: defaultValues.lateralSpacing,
        bedWidth: defaultValues.bedWith,
        edgeInRow: defaultValues.edgeInRow,
        edgeOffRow: defaultValues.edgeOffRow,
        max1: defaultValues.maxEmitters,
        max2: defaultValues.maxLaterals,
        min1: defaultValues.minLaterals,
        min2: defaultValues.minEmitters,
        step1: defaultValues.stepEmitters,
        step2: defaultValues.stepLaterals,
      }),
    );
  }, [currentEmitter]);

  useEffect(() => {
    const isCalculationAvailable =
      !!+emitterSpacing && !!+lateralSpacing && !!+bedWidth && (+edge !== 0 ? !!edgeInRow && !!edgeOffRow : true);

    if (!isCalculationAvailable || !Object.keys(currentEmitter)) return;

    const placementCalculated = +edge === 0 ? placement : 5;

    const values = {
      unitSettings: units,
      FieldRainAt: null,
      tempdrainat: null,
      Placement: placementCalculated,
      cbEDCEmitter: { SelectedItem: currentEmitter },
      dEDGEPOSITIONX: +edgeInRow,
      dEDGEPOSITIONY: +edgeOffRow,
      EdgeType: +edge,
      _Shadows: false,
      lblMaximumUnit: units.appnRate,
      txtBedWidth: +bedWidth,
      txtLateralSpacing: lateralSpacing.toString(),
      txtEmitterSpacing: emitterSpacing.toString(),
      lblLateralSpacingUnit: units.lateralSpacing,
      lblEmitterSpacingUnit: units.emitterSpacing,
      lblBedWidthUnit: units.length,
      lastplacement: placementCalculated,
      BetweenPlants: +betweenPlants,
      BetweenRows: +betweenRows,
      PlantDiameter: +plantDiameter,
      StaggeredRows: staggeredRows,
      GroupPlantRows: false,
      RowsinGroup: 0,
      BetweenGroups: 0,
      InRows: inRows,
      OffRows: offRows,
      CenterPlantsbetweenlaterals: centerPlantsBetweenLaterals,
      range: true,
      _ShowGrowth: true,
      Graph: true,
      mouseClickPositionY1,
      mouseClickPositionY2,
    };

    console.log('####: values', values);

    const result = HandleField(values);

    if (result.Minimum !== undefined && result.Minimum <= 0) {
      dispatch(setEDCStateError({ minimum: true }));
    } else {
      dispatch(setEDCStateError({ minimum: false }));
    }
    if (result.Maximum !== undefined && result.Maximum <= 0) {
      dispatch(setEDCStateError({ maximum: true }));
    } else {
      dispatch(setEDCStateError({ maximum: false }));
    }
    if (result.Average !== undefined && result.Average <= 0) {
      dispatch(setEDCStateError({ average: true }));
    } else {
      dispatch(setEDCStateError({ average: false }));
    }
    if (result.TheCu !== undefined && result.TheCu <= 0) {
      dispatch(setEDCStateError({ cu: true }));
    } else {
      dispatch(setEDCStateError({ cu: false }));
    }
    if (result.TheDu !== undefined && result.TheDu <= 0) {
      dispatch(setEDCStateError({ du: true }));
    } else {
      dispatch(setEDCStateError({ du: false }));
    }

    dispatch(setEDCCalcResult(result as EDCCalcResult));
    dispatch(
      setEDCStateValue({
        average: result.Average,
        minimum: result.Minimum,
        maximum: result.Maximum,
        du: result.TheDu,
        cu: result.TheCu,
        sprinklersPerHa: Math.round(result.txtSprinklerCount ? +result.txtSprinklerCount : 0),
      }),
    );

    const transformedFieldAtRain = getTransformedArray(
      result.FieldRainAt?.filter((item: any[]) => item.filter(Boolean).length),
      +placement,
      +edge,
    );
    const transformedTempRainAt = getTransformedArray(
      result.tempdrainat?.filter((item: any[]) => item.filter(Boolean).length) ?? [],
      +placement,
      +edge,
    );
    const array = result?.tempdrainat?.length ? transformedTempRainAt : transformedFieldAtRain;

    const { minSquare, sc } = calculateSC(array, scPercent, result.EdgeLine ?? [], edge);
    const { maxSquare, wettest } = calculateWettest(array, wettestPercent, result.EdgeLine ?? [], edge);

    dispatch(setEDCStateValue({ scSquare: minSquare, wettestSquare: maxSquare, sc, wettest }));

    console.log('####: result', result);
  }, [
    emitterSpacing,
    lateralSpacing,
    edgeInRow,
    edgeOffRow,
    bedWidth,
    placement,
    edge,
    lateral,
    scPercent,
    wettestPercent,
  ]);

  return (
    <Grid container rowSpacing={1}>
      <Grid item container xs={12} columnSpacing={isTablet ? 1 : 3} rowSpacing={1}>
        <Grid item xs={6}>
          <Input
            label={`${t('minimum')} (${units.appnRate})`}
            value={edcValues.minimum}
            isResultBox
            isErrorBox={edcErrors.minimum}
          />
        </Grid>
        <Grid item xs={6}>
          <Input label='CU' value={edcValues.cu} isResultBox isErrorBox={edcErrors.cu} />
        </Grid>
      </Grid>

      <Grid item container xs={12} columnSpacing={isTablet ? 1 : 3} rowSpacing={1}>
        <Grid item xs={6}>
          <Input
            label={`${t('maximum')} (${units.appnRate})`}
            value={edcValues.maximum}
            isResultBox
            isErrorBox={edcErrors.maximum}
          />
        </Grid>
        <Grid item xs={6}>
          <Input label='DU' value={edcValues.du} isResultBox isErrorBox={edcErrors.du} />
        </Grid>
      </Grid>

      <Grid item container xs={12} columnSpacing={isTablet ? 1 : 3} rowSpacing={1}>
        <Grid item xs={isMobile ? 12 : 6}>
          <Input
            label={`${t('average')} (${units.appnRate})`}
            value={edcValues.average}
            isResultBox
            isErrorBox={edcErrors.average}
          />
        </Grid>
        <Grid item container xs={isMobile ? 12 : 6} columnSpacing={isTablet ? 1 : 2}>
          <Grid item xs={6}>
            <Input
              label={`${t('Wettest')} (${units.appnRate})`}
              value={edcValues.wettest}
              isResultBox
              isErrorBox={edcErrors.wettest}
              additionalInput={<WettestPercentInput />}
            />
          </Grid>
          <Grid item xs={6}>
            <Input
              label='SC'
              value={edcValues.sc}
              isResultBox
              isErrorBox={edcErrors.sc}
              additionalInput={<SCPercentInput />}
            />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export const ResultsBlock: FC = () => {
  const isTablet = useMediaQuery('(max-width:850px)');
  const { t } = useTranslation();

  return (
    <Box width='100%' mt={2}>
      {isTablet ? (
        <Accordion header={<IconLabel iconSrc={results_ico}>{t('results')}</IconLabel>}>
          <ResultsContent />
        </Accordion>
      ) : (
        <Box>
          <IconLabel iconSrc={results_ico}>{t('results')}</IconLabel>
          <PaperBox>
            <ResultsContent />
          </PaperBox>
        </Box>
      )}
    </Box>
  );
};
