import { Box, CircularProgress, TextField, TextFieldProps, Typography } from '@mui/material';
import { forwardRef } from 'react';

type Props = TextFieldProps & {
  loading?: boolean;
};

export const RegisterInput = forwardRef<unknown, Props>((props, ref) => {
  return (
    <Box sx={{ position: 'relative' }}>
      <Typography
        sx={{
          color: '#242731',
          fontFamily: 'Roboto',
          fontSize: '18px',
          lineHeight: '130%',
          letterSpacing: '0.18px',
          mb: '8px',
        }}
      >
        {props.label}
      </Typography>
      <TextField
        {...props}
        sx={{
          '& .MuiInputBase-root': { borderRadius: '8px' },
          input: {
            height: '44px',
            borderColor: props.error ? '#F00' : 'rgba(177, 177, 177, 1)',
          },
        }}
        fullWidth
        label={''}
        inputRef={ref}
        color="primary"
        InputLabelProps={{ shrink: true }}
        inputProps={{
          'data-hj-allow': '',
          sx: { px: 1, py: 0 },
          min: 0,

          ...props.inputProps,
        }}
      />
      {props.loading && (
        <Box sx={{ position: 'absolute', height: '22px', bottom: 10, right: 10 }}>
          <CircularProgress size={22} />
        </Box>
      )}
    </Box>
  );
});
