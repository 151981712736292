import { Grid, useMediaQuery } from '@mui/material';
import { ChangeEvent, FC, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from 'shared/hooks';
import { formattedInputValue } from 'shared/lib';
import { irrigationCalculate } from 'shared/lib/calculation/Irrigation/irrigationCalculate';
import { IrrigationCalcResult } from 'shared/lib/calculation/models';
import { CatalogItem } from 'shared/models';
import {
  setProjectValue,
  setSystemSummaryStateError,
  setSystemSummaryStateValue,
  toggleSystemSummaryReport,
} from 'shared/slices';

import { Button, Input, PaperBox, Row, ShepherdBlock } from 'shared/ui';

interface Props {
  currentProduct: CatalogItem;
}

export const Irrigation: FC<Props> = ({ currentProduct }) => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const { projectData } = useAppSelector((st) => st.projectData);
  const { units } = useAppSelector((st) => st.units);
  const { systemSummaryValues, systemSummaryErrors } = useAppSelector((st) => st.systemSummaryState);
  const isMobile = useMediaQuery('(max-width:550px)');

  useEffect(() => {
    if (!currentProduct) return;

    const value = (projectData.region === 'USA' ? currentProduct.AltQnom : currentProduct.Qnom) as number;

    dispatch(setSystemSummaryStateValue({ flowRate: value }));
  }, [currentProduct]);

  const changeZoneIrrigationArea = (e: ChangeEvent<HTMLInputElement>) => {
    dispatch(setProjectValue({ zoneIrrigationArea: formattedInputValue(e) }));
  };
  const changeZoneEmitterFlowRate = (e: ChangeEvent<HTMLInputElement>) => {
    dispatch(setSystemSummaryStateValue({ flowRate: formattedInputValue(e) }));
  };
  const changeZoneEmitterSpacing = (e: ChangeEvent<HTMLInputElement>) => {
    dispatch(setProjectValue({ zoneEmitterSpacing: formattedInputValue(e) }));
  };
  const changeAvgPeakConsumptiveUse = (e: ChangeEvent<HTMLInputElement>) => {
    dispatch(setProjectValue({ avgPeakConsumptiveUse: formattedInputValue(e) }));
  };
  const changeSourceFlowRate = (e: ChangeEvent<HTMLInputElement>) => {
    dispatch(setProjectValue({ sourceFlowRate: formattedInputValue(e) }));
  };
  const changeZoneMaxIrrigationTimePerDay = (e: ChangeEvent<HTMLInputElement>) => {
    dispatch(setProjectValue({ zoneMaxIrrigationTimePerDay: formattedInputValue(e) }));
  };
  const changeZoneLateralsPerRow = (e: ChangeEvent<HTMLInputElement>) => {
    dispatch(setProjectValue({ zoneLateralsPerRow: formattedInputValue(e) }));
  };
  const changeZoneLateralSpacing = (e: ChangeEvent<HTMLInputElement>) => {
    dispatch(setProjectValue({ manifoldSpacing: formattedInputValue(e) }));
  };

  const onCalculate = () => {
    const values = {
      ZoneEmitterFlow: +systemSummaryValues.flowRate,
      ZoneEmitterSpacing: +projectData.zoneEmitterSpacing,
      ZoneLateralSpacing: +projectData.manifoldSpacing,
      ZoneLateralsPerRow: +projectData.zoneLateralsPerRow,
      IrrigationArea: +projectData.zoneIrrigationArea,
      SourceFlowRate: +projectData.sourceFlowRate,
      AvgPeakConsumption: +projectData.avgPeakConsumptiveUse,
      MaxIrrigationTimePerDay: +projectData.zoneMaxIrrigationTimePerDay,
    };

    console.log('####: values', values);

    const { waterSourceUsage, ...systemSummaryValidations } = systemSummaryErrors;

    const isValid = Object.values(systemSummaryValidations).some((item) => item);

    if (isValid) return;

    const result = irrigationCalculate(values, units) as IrrigationCalcResult;

    console.log('####: result', result);

    dispatch(
      setSystemSummaryStateValue({
        avgIrrigationRate: result.ApplicationRate,
        numberOfOperations: result.NumberOfOperations,
        maxTotalIrrigationTime: result.TotalIrrigationTime,
        avgDischarge: result.ZoneAvgDischarge,
        waterSourceUsage: result.WellUtilization,
        timeOfIrrigation: result.ZoneOpTime,
      }),
    );
  };

  const openSystemSummaryReport = () => dispatch(toggleSystemSummaryReport());

  //VALIDATIONS
  useEffect(() => {
    if (+projectData.zoneEmitterSpacing <= 0) {
      dispatch(setSystemSummaryStateError({ emitterSpacing: true }));
    } else {
      dispatch(setSystemSummaryStateError({ emitterSpacing: false }));
    }
  }, [projectData.zoneEmitterSpacing]);

  useEffect(() => {
    if (+projectData.manifoldSpacing <= 0) {
      dispatch(setSystemSummaryStateError({ lateralSpacing: true }));
    } else {
      dispatch(setSystemSummaryStateError({ lateralSpacing: false }));
    }
  }, [projectData.manifoldSpacing]);

  useEffect(() => {
    if (+projectData.zoneLateralsPerRow <= 0) {
      dispatch(setSystemSummaryStateError({ numberOfLateral: true }));
    } else {
      dispatch(setSystemSummaryStateError({ numberOfLateral: false }));
    }
  }, [projectData.zoneLateralsPerRow]);

  useEffect(() => {
    if (+projectData.zoneIrrigationArea <= 0) {
      dispatch(setSystemSummaryStateError({ irrigationArea: true }));
    } else {
      dispatch(setSystemSummaryStateError({ irrigationArea: false }));
    }
  }, [projectData.zoneIrrigationArea]);

  useEffect(() => {
    if (+systemSummaryValues.flowRate <= 0) {
      dispatch(setSystemSummaryStateError({ flowRate: true }));
    } else {
      dispatch(setSystemSummaryStateError({ flowRate: false }));
    }
  }, [systemSummaryValues.flowRate]);

  useEffect(() => {
    if (+systemSummaryValues.waterSourceUsage > 100) {
      dispatch(setSystemSummaryStateError({ waterSourceUsage: true }));
    } else {
      dispatch(setSystemSummaryStateError({ waterSourceUsage: false }));
    }
  }, [systemSummaryValues.waterSourceUsage]);

  useEffect(() => {
    if (+projectData.avgPeakConsumptiveUse <= 0) {
      dispatch(setSystemSummaryStateError({ avgPeak: true }));
    } else {
      dispatch(setSystemSummaryStateError({ avgPeak: false }));
    }
  }, [projectData.avgPeakConsumptiveUse]);

  useEffect(() => {
    const avgIrrigationRate =
      (+systemSummaryValues.flowRate / +projectData.zoneEmitterSpacing / +projectData.manifoldSpacing) *
      +projectData.zoneLateralsPerRow;

    const timeOfIrrigation = +projectData.avgPeakConsumptiveUse / avgIrrigationRate;
    const operationPerCycle = Number.parseInt(
      Math.floor(+projectData.zoneMaxIrrigationTimePerDay / timeOfIrrigation).toString(),
    );
    console.log('TIME', timeOfIrrigation);
    console.log('projectData.zoneMaxIrrigationTimePerDay', projectData.zoneMaxIrrigationTimePerDay);

    console.log(operationPerCycle);


    if (operationPerCycle <= 0) {
      dispatch(setSystemSummaryStateError({ maxIrrigation: true }));
    } else {
      dispatch(setSystemSummaryStateError({ maxIrrigation: false }));
    }
  }, [projectData.zoneMaxIrrigationTimePerDay]);

  return (
    <PaperBox height={'100%'}>
      <Grid item container xs={12} columnSpacing={3} rowSpacing={1}>
        <Grid item xs={isMobile ? 12 : 4}>
          <Input
            label={`${t('irrigationArea')} (${units.area})`}
            errorText={`${t('Not Valid')}`}
            isErrorBox={systemSummaryErrors.irrigationArea}
            value={projectData.zoneIrrigationArea}
            onChange={changeZoneIrrigationArea}
          />
        </Grid>
        {!isMobile && <Grid item xs={4} />}

        <Grid item xs={isMobile ? 12 : 4}>
          <Input label={`${t('numZones')}`} value={systemSummaryValues.numberOfOperations} isResultBox />
        </Grid>

        <Grid item xs={isMobile ? 12 : 4}>
          <Input
            label={`${t('emitterFlowRate')} (${units.flow})`}
            value={systemSummaryValues.flowRate}
            onChange={changeZoneEmitterFlowRate}
            errorText={`${t('Not Valid')}`}
            isErrorBox={systemSummaryErrors.flowRate}
          />
        </Grid>
        <Grid item xs={isMobile ? 12 : 4}>
          <Input
            label={`${t('emitterSpacing')} (${units.emitterSpacing})`}
            errorText={`${t('Not Valid')}`}
            isErrorBox={systemSummaryErrors.emitterSpacing}
            value={projectData.zoneEmitterSpacing}
            onChange={changeZoneEmitterSpacing}
          />
        </Grid>
        <Grid item xs={isMobile ? 12 : 4}>
          <Input label={`${t('opTime')} (h)`} value={systemSummaryValues.timeOfIrrigation} isResultBox />
        </Grid>

        <Grid item xs={isMobile ? 12 : 4}>
          <Input
            label={`${t('lateralPerRow')}`}
            value={projectData.zoneLateralsPerRow}
            onChange={changeZoneLateralsPerRow}
            errorText={`${t('Not Valid')}`}
            isErrorBox={systemSummaryErrors.numberOfLateral}
          />
        </Grid>
        <Grid item xs={isMobile ? 12 : 4}>
          <Input
            label={`${t('lateralSpacing')} (${units.lateralSpacing})`}
            value={projectData.manifoldSpacing}
            onChange={changeZoneLateralSpacing}
            errorText={`${t('Not Valid')}`}
            isErrorBox={systemSummaryErrors.lateralSpacing}
          />
        </Grid>
        <Grid item xs={isMobile ? 12 : 4}>
          <Input
            label={`${t('avgDischarge')} (${units.totalFlow})`}
            value={systemSummaryValues.avgDischarge}
            isResultBox
          />
        </Grid>

        <Grid item xs={isMobile ? 12 : 4}>
          <Input
            label={`${t('avgIrrigationRate')} (${units.appnRate})`}
            value={systemSummaryValues.avgIrrigationRate}
            isResultBox
          />
        </Grid>
        <Grid item xs={isMobile ? 12 : 4}>
          <Input
            label={`${t('avgPeakConsumption')} (${units.appnDepth})`}
            value={projectData.avgPeakConsumptiveUse}
            onChange={changeAvgPeakConsumptiveUse}
            errorText={`${t('Not Valid')}`}
            isErrorBox={systemSummaryErrors.avgPeak}
          />
        </Grid>
        <Grid item xs={isMobile ? 12 : 4}>
          <Input
            label={`${t('wellUtilization')} (%)`}
            isErrorBox={systemSummaryErrors.waterSourceUsage}
            errorText={`${t('Not Valid')}`}
            value={systemSummaryValues.waterSourceUsage}
            isResultBox
          />
        </Grid>

        <Grid item xs={isMobile ? 12 : 4}>
          <Input
            label={`${t('wellCapcity')} (${units.totalFlow})`}
            value={projectData.sourceFlowRate}
            onChange={changeSourceFlowRate}
            errorText={`${t('Not Valid')}`}
            isErrorBox={systemSummaryErrors.waterSource}
          />
        </Grid>
        <Grid item xs={isMobile ? 12 : 4}>
          <Input
            label={`${t('maxOpTime')} (h)`}
            value={projectData.zoneMaxIrrigationTimePerDay}
            onChange={changeZoneMaxIrrigationTimePerDay}
            errorText={`${t('Not Valid')}`}
            isErrorBox={systemSummaryErrors.maxIrrigation}
          />
        </Grid>
        <Grid item xs={isMobile ? 12 : 4}>
          <Input label={`${t('maxCycletime')} (h)`} value={systemSummaryValues.maxTotalIrrigationTime} isResultBox />
        </Grid>
      </Grid>

      <Row justifyContent={isMobile ? 'center' : 'flex-end'} sx={{ mt: '10px' }}>
        <ShepherdBlock id='sys-sum-buttons'>
          <Row spacing='20px' width={isMobile ? '100%' : 'auto'}>
            <Button sx={{ width: isMobile ? '100%' : 'auto' }} onClick={onCalculate}>
              {t('calculate')}
            </Button>
            {!isMobile && (
              <Button onClick={openSystemSummaryReport} sx={{ width: isMobile ? '100%' : 'auto' }}>
                {t('showReport')}
              </Button>
            )}
          </Row>
        </ShepherdBlock>
      </Row>
    </PaperBox>
  );
};
