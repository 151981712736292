import { FC, PropsWithChildren, useEffect } from 'react';
import { useAppDispatch, useAppSelector } from 'shared/hooks';
import { getConvertedProjectValuesFromBE } from 'shared/lib';
import {
  getComparisonEmittersEDCThunk,
  getComparisonEmittersFamiliesThunk,
  getEmitterGroupsThunk,
  getEmittersEDCThunk,
  getEmittersFamiliesThunk,
  getEmittersThunk,
  getLateralGroupsThunk,
  getLateralsThunk,
  getMainlineGroupsThunk,
  getMainlinesThunk,
  getMasterGroupsThunk,
  getProjectDataThunk,
  getProjectsThunk,
  getSubmainGroupsThunk,
  getSubmainsThunk,
  getUnitsThunk,
  setProjectValue,
} from 'shared/slices';

export const PrefetchData: FC<PropsWithChildren> = ({ children }) => {
  const dispatch = useAppDispatch();
  const { username } = useAppSelector((state) => state.auth);
  const { currentProject, loading: isProjectsLoading } = useAppSelector((state) => state.projects);
  const {
    emitterFamilies,
    comparisonEmitterFamilies,
    loading: isEmitterFamiliesLoading,
    comparisonLoading: isComparisonEmitterFamiliesLoading,
  } = useAppSelector((state) => state.emitterFamilies);
  const { loading: isMasterGroupsLoading } = useAppSelector((state) => state.masterGroups);
  const { projectData, loading: isProjectDataLoading } = useAppSelector((state) => state.projectData);
  const { units, loading: isUnitsLoading } = useAppSelector((state) => state.units);

  useEffect(() => {
    if (!username) return;

    const userId = localStorage.getItem('ht-user-id');
    const userToken = localStorage.getItem('token');

    if (!userId || !userToken) return;

    dispatch(getProjectsThunk());
    dispatch(getMasterGroupsThunk());
    dispatch(getEmittersFamiliesThunk());
    dispatch(getComparisonEmittersFamiliesThunk());
  }, [username]);

  useEffect(() => {
    if (isProjectsLoading || isMasterGroupsLoading) return;

    const userId = localStorage.getItem('ht-user-id');

    if (!userId) return;

    dispatch(getUnitsThunk());
    dispatch(getProjectDataThunk({ projectId: currentProject.projectID }));
  }, [isProjectsLoading, isMasterGroupsLoading]);

  useEffect(() => {
    if (isEmitterFamiliesLoading) return;

    dispatch(getEmittersEDCThunk({ ename: emitterFamilies[0] }));
  }, [isEmitterFamiliesLoading]);

  useEffect(() => {
    if (isComparisonEmitterFamiliesLoading) return;

    dispatch(getComparisonEmittersEDCThunk({ ename: comparisonEmitterFamilies[1] }));
  }, [isComparisonEmitterFamiliesLoading]);

  useEffect(() => {
    if (isProjectDataLoading || isUnitsLoading) return;

    dispatch(setProjectValue(getConvertedProjectValuesFromBE({ projectData, destUnits: units })));
  }, [isProjectDataLoading, isUnitsLoading]);

  useEffect(() => {
    if (isProjectDataLoading) return;

    const { lateralGroup, emitterGroup, submainGroup, mainlineGroup, region, standard, limited } = projectData;

    dispatch(getLateralGroupsThunk({ masterGroup: projectData.lateralMasterGroup }));
    dispatch(getEmitterGroupsThunk({ masterGroup: projectData.emitterMasterGroup }));
    dispatch(getMainlineGroupsThunk({ masterGroup: projectData.mainlineMasterGroup }));
    dispatch(getSubmainGroupsThunk({ masterGroup: projectData.submainMasterGroup }));

    dispatch(getLateralsThunk({ group: lateralGroup, region: region, standard: standard, limited: limited }));
    dispatch(getEmittersThunk({ group: emitterGroup, region: region, standard: standard, limited: limited }));
    dispatch(getSubmainsThunk({ group: submainGroup, region: region, standard: standard, limited: limited }));
    dispatch(getMainlinesThunk({ group: mainlineGroup, region: region, standard: standard, limited: limited }));
  }, [isProjectDataLoading]);

  return <>{children}</>;
};
