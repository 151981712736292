import { Box, BoxProps, styled } from '@mui/material';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { system_summary_bg } from 'shared/assets';
import { useAppSelector } from 'shared/hooks';
import { Math_round } from 'shared/lib/calculation/mathRound';
import { PaperBox } from 'shared/ui';

const GreenBack = styled(Box)(({ theme }) => ({
  borderRadius: '10px',
  backgroundColor: theme.palette.primary.main,
  height: 'auto',
  position: 'absolute',
  zIndex: 2,
  color: 'white',
  fontSize: '14px',
  padding: '0px 7px',
  fontWeight: 400,
}));
const Dashed = styled(Box)(({ theme }) => ({
  position: 'absolute',
  zIndex: -1,
  width: '1px',
  border: `1px dashed ${theme.palette.primary.main}`,
}));

interface Props extends BoxProps {
  bg?: string;
}

export const MainControlBlock: FC<Props> = ({ bg, sx }) => {
  const { t } = useTranslation();
  const { lateralValues } = useAppSelector((st) => st.lateralState);
  const { mainlineValues } = useAppSelector((st) => st.mainlineState);
  const { submainValues } = useAppSelector((st) => st.submainState);
  const { projectData } = useAppSelector((st) => st.projectData);
  const { units } = useAppSelector((st) => st.units);

  // const valveOutletPressure = Math_round(
  //   Math_round(
  //     Math_round(+projectData.LateralInletPressure + submainValues.totalHeadloss, 3) + +mainlineValues.valveHeadloss,
  //     3
  //   ) + +mainlineValues.valveHeadloss,
  //   3
  // );

  const pressureAtHead = Math_round(
    +mainlineValues.valveHeadloss + submainValues.valvePressure + +mainlineValues.pipesHeadloss,
    3,
  );

  const pressureAtEnd = Math_round(
    lateralValues?.resultReportArray[lateralValues?.resultReportArray.length - 1]?.inletPressure ?? 0,
    3,
  );

  return (
    <PaperBox
      sx={{
        ml: { xs: 0, xl: '15px' },
        height: '380px',
        p: 0,
        overflow: 'hidden',
        position: 'relative',
        background: '#fff',
        ...sx,
      }}
    >
      <Box
        sx={{
          height: '100%',
          position: 'absolute',
          top: 0,
          right: '-45px',
        }}
      >
        <Box component='img' src={bg ?? system_summary_bg} sx={{ height: '100%' }} />

        <GreenBack sx={{ left: '-50px', top: '30px' }}>
          {`${t('headOutletPressure')} = ${pressureAtHead} ${units.pressure}`}
          <Dashed sx={{ height: '30px', left: '87px' }} />
        </GreenBack>

        <GreenBack sx={{ left: '-70px', top: '240px' }}>
          {`${t('pipesHeadloss')} = ${mainlineValues.pipesHeadloss} ${units.pressure}`}
          <Dashed sx={{ height: '80px', left: '120px', bottom: '13px' }} />
        </GreenBack>

        <GreenBack sx={{ left: '250px', top: '10px' }}>
          {`${t('pumpPressure')} = ${mainlineValues.pumpPressure} ${units.pressure}`}
          <Dashed sx={{ height: '40px', top: '18px', left: '13px', transform: 'rotateZ(45deg)' }} />
        </GreenBack>

        <GreenBack sx={{ left: '250px', top: '100px' }}>
          {`${t('valveOutletPressure')} = ${submainValues.valvePressure} ${units.pressure}`}
          <Dashed sx={{ height: '50px', left: 0, top: 0, transform: 'rotate(65deg)' }} />
        </GreenBack>

        <GreenBack sx={{ left: '370px', top: '170px' }}>
          {`${t('prAtLateralEnd')} = ${pressureAtEnd} ${units.pressure}`}
          <Dashed sx={{ height: '70px', right: '80px' }} />
        </GreenBack>

        <GreenBack sx={{ left: '170px', top: '210px' }}>
          {`${t('lateralInletPr')} = ${projectData.lateralInletPressure} ${units.pressure}`}
          <Dashed sx={{ height: '85px', right: '17px', top: 0 }} />
        </GreenBack>

        <GreenBack sx={{ left: '345px', top: '340px' }}>
          {`${t('submainHeadloss')} = ${submainValues.totalHeadloss} ${units.pressure}`}
          <Dashed sx={{ height: '37px', left: '20px', bottom: '10px' }} />
        </GreenBack>
      </Box>
    </PaperBox>
  );
};
