import { ChangeEvent, FC, useState } from 'react';
import { combined_ico, lateral_ico } from 'shared/assets';
import {
  Accordion,
  AvailableInfo,
  IconLabel,
  Input,
  PaperBox,
  ProductSelect,
  Row,
  Select,
  ShepherdBlock,
} from 'shared/ui';
import { Box, Checkbox, FormControlLabel, Grid, MenuItem, Typography, useMediaQuery } from '@mui/material';
import { useAppDispatch, useAppSelector, useLateralFilters } from 'shared/hooks';
import { useTranslation } from 'react-i18next';
import { setProjectValue } from '../../../../shared/slices';
import { formattedInputValue } from '../../../../shared/lib';

const LateralContent = () => {
  const dispatch = useAppDispatch();
  const isTablet = useMediaQuery('(max-width:850px)');
  const isMobile = useMediaQuery('(max-width:550px)');
  const { t } = useTranslation();

  const { projectData } = useAppSelector((st) => st.projectData);
  const { units } = useAppSelector(st => st.units);
  const { lateralGroups, lateralGroupsLoading, lateralsLoading } = useAppSelector((state) => state.laterals);

  const [manualSpacing, setManualSpacing] = useState(false);

  const changeEmitterSpacing = (e: ChangeEvent<HTMLInputElement>) => {
    dispatch(setProjectValue({ emitterSpacing: formattedInputValue(e) }));
  };

  const {
    masterGroups,
    masterGroupId,
    onMasterGroupChange,
    lateralSubtype,
    onGroupTypeChange,
    diameter,
    onDiameterChange,
    classType,
    onClassTypeChange,
    flowPerUnit,
    onFlowPerUnitChange,
    nominalFlow,
    onNominalFlowChange,
    spacing,
    onSpacingChange,
    diameters,
    classTypes,
    flowPerUnits,
    nominalFlows,
    spacings,
    lateralProduct,
    lateralsFiltered,
    onLateralChange,
  } = useLateralFilters();

  const isDriplineTypeChosen = masterGroupId === 2;

  const LabelInput = () => <FormControlLabel
    sx={{
      m: 0, fontSize: '11px', alignItems: 'center',
    }}
    control={<Checkbox
      size='small'
      sx={{ p: '0 5px 0 0' }}
      value={manualSpacing}
      checked={manualSpacing}
      onChange={(e) => setManualSpacing(e.target.checked)}
    />}
    label={<Typography
      noWrap
      sx={{
        fontSize: '11px',
        color: 'secondary.main',
        marginBottom: '3px',
      }}
    >
      {t('manual')}
    </Typography>}
  />;

  return (
    <>
      <Grid item container xs={12} columnSpacing={isMobile ? 1 : 3}>
        <Grid item xs={isTablet ? 6 : 3}>
          <Select
            label={`${t('type')}`}
            value={masterGroupId}
            onChange={(e) => onMasterGroupChange(+(e.target.value as string))}
          >
            {masterGroups.map((item) => (
              <MenuItem key={item.ID} value={item.ID}>
                {item.MASTERGROUPNAME}
              </MenuItem>
            ))}
          </Select>
        </Grid>

        <Grid item xs={isTablet ? 6 : 3}>
          <Select
            label={`${t('subtype')}`}
            value={lateralSubtype}
            onChange={onGroupTypeChange}
            loading={lateralGroupsLoading}
            disabled={lateralGroupsLoading}
          >
            {lateralGroups.map((item) => (
              <MenuItem key={item.GROUPS} value={item.GROUPS}>
                {item.GROUPTYPENAME}
              </MenuItem>
            ))}
          </Select>
        </Grid>

        <Grid item xs={isTablet ? 6 : 3}>
          <Select
            label={`${t('dia')}`}
            value={diameter}
            onChange={onDiameterChange}
            disabled={lateralGroupsLoading || lateralsLoading}
          >
            {diameters.map((item) => (
              <MenuItem key={item} value={item}>
                {item}
              </MenuItem>
            ))}
          </Select>
        </Grid>

        {!isDriplineTypeChosen && (
          <Grid item xs={isTablet ? 6 : 3}>
            <Select
              label={`${t('wall_thickness')}`}
              value={classType}
              onChange={onClassTypeChange}
              disabled={lateralGroupsLoading || lateralsLoading}
            >
              {classTypes.map((item) => (
                <MenuItem key={item} value={item}>
                  {item}
                </MenuItem>
              ))}
            </Select>
          </Grid>
        )}
      </Grid>

      {projectData.integrated && (
        <Grid item container columnSpacing={isMobile ? 1 : 3} xs={12}>
          {isDriplineTypeChosen && (
            <Grid item xs={isTablet ? 4 : 3}>
              <Select
                label={`${t('wall_thickness')}`}
                value={classType}
                onChange={onClassTypeChange}
                disabled={lateralGroupsLoading || lateralsLoading}
              >
                {classTypes.map((item) => (
                  <MenuItem key={item} value={item}>
                    {item}
                  </MenuItem>
                ))}
              </Select>
            </Grid>
          )}
          {!isDriplineTypeChosen && (
            <Grid item xs={isTablet ? 4 : 3}>
              <Select
                label={`${t('flowPer')}`}
                value={flowPerUnit}
                onChange={onFlowPerUnitChange}
                disabled={lateralGroupsLoading || lateralsLoading}
              >
                {flowPerUnits.map((item) => (
                  <MenuItem key={item} value={item}>
                    {item}
                  </MenuItem>
                ))}
              </Select>
            </Grid>
          )}

          <Grid item xs={isTablet ? 4 : 3}>
            <Select
              label={`${t('nominalFlow')}`}
              value={nominalFlow}
              onChange={onNominalFlowChange}
              disabled={lateralGroupsLoading || lateralsLoading}
            >
              {nominalFlows.map((item) => (
                <MenuItem key={item} value={item}>
                  {item}
                </MenuItem>
              ))}
            </Select>
          </Grid>

          <Grid item xs={isTablet ? 4 : 3}>
            {!manualSpacing && <Select
              label={`${t('spacing')}`}
              value={spacing}
              onChange={onSpacingChange}
              disabled={lateralGroupsLoading || lateralsLoading}
              labelInput={<LabelInput />}
            >
              {spacings.map((item) => (
                <MenuItem key={item} value={item}>
                  {item}
                </MenuItem>
              ))}
            </Select>}
            {manualSpacing &&
              <Input
                label={`${t('spacing')} (${units.lateralSpacing})`}
                value={projectData.emitterSpacing ?? 0}
                onChange={changeEmitterSpacing}
                inputProps={{ step: 0.1 }}
                labelInputPosition={'right'}
                labelInput={<LabelInput />}
              />
            }
          </Grid>
        </Grid>
      )}

      <Grid item container xs={12} alignItems='center' columnSpacing={isMobile ? 1 : 3}>
        <Grid item xs={isMobile ? 12 : 9}>
          <ShepherdBlock id='lateral-lateral-product-selected'>
            <ProductSelect
              label={`${t('selectedProduct')}`}
              value={lateralProduct}
              onChange={onLateralChange}
              options={lateralsFiltered}
              loading={lateralsLoading}
              disabled={lateralsLoading || lateralGroupsLoading}
              error={!lateralProduct}
            />
          </ShepherdBlock>
        </Grid>

        <Grid item xs={isMobile ? 12 : 3} mt={isMobile ? 2 : 0}>
          <AvailableInfo amount={lateralsFiltered.length} />
        </Grid>
      </Grid>
    </>
  );
};

export const LateralBlock: FC = () => {
  const isTablet = useMediaQuery('(max-width:850px)');

  const { projectData } = useAppSelector((st) => st.projectData);

  const { lateralProduct } = useLateralFilters();

  const { t } = useTranslation();

  return (
    <Box pt='10px' width='100%'>
      {isTablet ? (
        <Accordion
          defaultExpanded
          header={
            <Row
              sx={{
                whiteSpace: 'nowrap',
                textOverflow: 'ellipsis',
                overflow: 'hidden',
              }}
            >
              <IconLabel iconSrc={projectData.integrated ? combined_ico : lateral_ico}>{t('lateral')}</IconLabel>
              <Typography fontSize={14} lineHeight='16px' color='primary.main' ml={2} noWrap>
                {projectData.region === 'USA' ? lateralProduct.AltCATLOGDESC : lateralProduct.CATLOGDESC}
              </Typography>
            </Row>
          }
        >
          <LateralContent />
        </Accordion>
      ) : (
        <>
          <IconLabel iconSrc={projectData.integrated ? combined_ico : lateral_ico}>{t('lateral')}</IconLabel>

          <PaperBox>
            <LateralContent />
          </PaperBox>
        </>
      )}
    </Box>
  );
};
