import { ChangeEvent } from 'react';

export const formattedInputValue = (e: ChangeEvent<HTMLInputElement>) => {
  const value = e.target.value;

  if (value == '') {
    return '';
  }

  if (+value == 0) {
    return 0;
  }

  return +e.target.value;
};
