/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { customAxios } from 'shared/api';
import { LoginBody, LoginResponse } from 'shared/models';

export enum AuthState {
  WAITING = 'WAITING',
  SUCCESS = 'SUCCESS',
  FAILED = 'FAILED',
}

interface UserState {
  username: string;
  name: string;
  loading: boolean;
  authState: AuthState;
}

const initialState: UserState = {
  username: '',
  name: '',
  loading: false,
  authState: localStorage.getItem('user') ? AuthState.SUCCESS : AuthState.WAITING,
};

export const loginThunk = createAsyncThunk('login', async (body: LoginBody, thunkAPI) => {
  try {
    const response = await customAxios.post<LoginResponse>('be/Auth/login', {
      username: body.user,
      password: body.password,
      isFromOldHT: body.isFromOldHT,
    });

    if (response.data.data.token) {
      localStorage.setItem('user', window.btoa(encodeURIComponent(JSON.stringify(body))));

      localStorage.setItem('ht-user-id', response.data.data.userId);
      localStorage.setItem('token', response.data.data.token);
    }

    return response.data;
  } catch (e: any) {
    return thunkAPI.rejectWithValue(e.response.data.status);
  }
});

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setUsernameFromLS(state) {
      const userJson = localStorage.getItem('user')!;
      if (userJson) {
        const user = JSON.parse(decodeURIComponent(window.atob(userJson))) as LoginBody;

        state.username = user.user;
      }
    },
    logout(state) {
      state.authState = AuthState.WAITING;
      state.username = '';
      localStorage.removeItem('user');
      localStorage.removeItem('ht-user-id');
      localStorage.removeItem('token');
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(loginThunk.pending.type, (state) => {
        state.loading = true;
        state.authState = AuthState.WAITING;
      })
      .addCase(loginThunk.fulfilled.type, (state, action: PayloadAction<LoginResponse>) => {
        if (action.payload.data.token) {
          const userJson = localStorage.getItem('user')!;
          const user = JSON.parse(decodeURIComponent(window.atob(userJson))) as LoginBody;

          state.name = action.payload.data?.name ?? '';
          state.username = user.user;
          state.authState = AuthState.SUCCESS;
        } else {
          state.authState = AuthState.FAILED;
        }
        state.loading = false;
      })
      .addCase(loginThunk.rejected.type, (state) => {
        state.loading = false;
        state.authState = AuthState.WAITING;
      });
  },
});

export const { logout, setUsernameFromLS } = authSlice.actions;

export const authReducer = authSlice.reducer;
