/* eslint-disable react/no-unescaped-entities */
import { Box, Button, CircularProgress, Dialog, Typography } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useAppDispatch } from 'shared/hooks';
import { toggleLanguage } from 'shared/slices';

interface Props {
  open: boolean;
  onAccept: () => void;
  onDoNotAccept: () => void;
  loading?: boolean;
}

export const EndUserLicense: React.FC<Props> = ({ open, onAccept, onDoNotAccept, loading }) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const openLanguagePopup = () => dispatch(toggleLanguage());

  return (
    <Dialog maxWidth="lg" open={open}>
      <Box p={2} sx={{ position: 'relative' }}>
        <Box mb={2}>
          <Box display="flex" justifyContent="flex-end">
            <Button variant="outlined" onClick={openLanguagePopup} sx={{ fontSize: 12, py: 0 }}>
              {t('end_user_license_agreement_change_lang')}
            </Button>
          </Box>

          <Typography variant="h4" align="center" color="primary">
            {t('end_user_license_agreement_1')}
          </Typography>

          <Typography>{t('end_user_license_agreement_2')}</Typography>
          <Typography>{t('end_user_license_agreement_3')}</Typography>

          <Typography>{t('end_user_license_agreement_4')}</Typography>
          <Typography>{t('end_user_license_agreement_5')}</Typography>
          <Typography>{t('end_user_license_agreement_6')}</Typography>
          <Typography>{t('end_user_license_agreement_7')}</Typography>
          <Typography>{t('end_user_license_agreement_8')}</Typography>
          <Typography>{t('end_user_license_agreement_9')}</Typography>
          <Typography>{t('end_user_license_agreement_10')}</Typography>
          <Typography>{t('end_user_license_agreement_11')}</Typography>
          <Typography>{t('end_user_license_agreement_12')}</Typography>
          <Typography variant="body2" align="center" mt={1} mb={2}>
            {t('end_user_license_agreement_13')}
          </Typography>
          <Typography>{t('end_user_license_agreement_14')}</Typography>
          <Typography>{t('end_user_license_agreement_15')}</Typography>
        </Box>

        <Box display="flex" columnGap={2}>
          <Box display="flex" flexBasis="50%" justifyContent="center">
            <Button variant="contained" onClick={onAccept}>
              {t('end_user_license_agreement_i_accept')}
            </Button>
          </Box>
          <Box display="flex" flexBasis="50%" justifyContent="center">
            <Button variant="outlined" onClick={onDoNotAccept}>
              {t('end_user_license_agreement_i_dont_accept')}
            </Button>
          </Box>
        </Box>

        {loading && (
          <Box sx={{ position: 'absolute', bottom: '300px', left: 'calc(50% - 30px)' }}>
            <CircularProgress size={50} />
          </Box>
        )}
      </Box>
    </Dialog>
  );
};
