import { Box, Button, Stack, Typography, styled, useMediaQuery } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { useEffect, useMemo, useRef, useState } from 'react';
import { useNavigate } from 'react-router';
import { AuthInput } from 'shared/ui';
import { LoginBody } from 'shared/models';
import { useAppDispatch, useAppSelector, useToast } from 'shared/hooks';
import { useTranslation } from 'react-i18next';
import { LoginHeader } from 'features';
import { customAxios } from 'shared/api';
import { loginThunk } from 'shared/slices';

const Text = styled(Typography)(() => ({
  fontSize: '11px',
  fontWeight: 400,
  margin: '20px 0 8px 20px',
  textDecoration: 'none',
}));

const btnStyles = {
  textTransform: 'capitalize',
  borderRadius: '8px',
  minWidth: '120px',
  height: '48px',
  fontSize: 18,
};

export const RedirectedLoginForm = () => {
  const { showError } = useToast();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const isMobile = useMediaQuery('(max-width:510px)');
  const [loading, setLoading] = useState(false);
  const dispatch = useAppDispatch();

  const [loginData, setLoginData] = useState({ username: '', password: '' });
  const handleLoginDataChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setLoginData((prev) => ({ ...prev, [e.target.name]: e.target.value }));
  };

  useEffect(() => {
    const mergeData = JSON.parse(localStorage.getItem('mergeData') as string);
    mergeData && setLoginData({ username: mergeData?.email, password: '' });
  }, []);

  const handleLoginClick = async () => {
    try {
      setLoading(true);

      let mergeBody = JSON.parse(localStorage.getItem('mergeData') as string);
      mergeBody = { appType: 0, ...mergeBody };
      await customAxios.post('/be/User/merge-ht-user', mergeBody);

      const body: LoginBody = {
        user: loginData.username,
        password: loginData.password,
        rememberMe: false,
        isFromOldHT: true,
      };

      await dispatch(loginThunk(body)).unwrap();

      navigate('/lateral');
    } catch (e: any) {
      console.log(e);
      showError(t('wrong_login_credentials'));
    } finally {
      setLoading(false);
    }
  };

  const onCancelClick = () => {
    navigate('/register');
    localStorage.removeItem('mergeData');
  };

  return (
    <>
      <Stack alignItems="center" sx={{}}>
        <Box
          sx={{
            p: '40px 24px 20px 24px',
            width: isMobile ? '100%' : '480px',
            boxShadow: '0px -0.5px 4px 0px rgba(0, 0, 0, 0.25)',
            borderRadius: '16px',
          }}
        >
          <LoginHeader />

          <Stack mt={1} sx={{}}>
            <Box flexBasis="100%">
              <Text>{t('login')}</Text>
              <AuthInput
                name="username"
                value={loginData.username}
                onChange={handleLoginDataChange}
                required
                autoFocus
                placeholder={`${t('enter_email')}`}
                disabled
              />
            </Box>

            <Box flexBasis="100%" mb={2}>
              <Text>{t('password')}</Text>

              <AuthInput
                name="password"
                value={loginData.password}
                onChange={handleLoginDataChange}
                required
                // type="password"
                placeholder={`${t('enter_password')}`}
                password
              />
            </Box>

            <Stack direction="row" justifyContent="flex-end" columnGap={3} mt={1}>
              <Box>
                <Button
                  onClick={onCancelClick}
                  variant="outlined"
                  sx={{
                    ...btnStyles,
                    color: 'black',
                    borderColor: 'black',
                  }}
                >
                  {t('cancel')}
                </Button>
              </Box>
              <Box>
                <LoadingButton
                  onClick={() => handleLoginClick()}
                  loading={loading}
                  fullWidth
                  variant="contained"
                  color="primary"
                  sx={{ ...btnStyles }}
                  disabled={!loginData.password}
                >
                  {t('login')}
                </LoadingButton>
              </Box>
            </Stack>
          </Stack>
        </Box>
      </Stack>
    </>
  );
};
